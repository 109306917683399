<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title class="headline">Shift QR Code</v-card-title>
      <v-card-text>
        <div v-if="shift">
          <p>Shift Name: {{ shift.title }}</p> 
          <!-- Display QR Code as Image -->
          <img v-if="qrCode" :src="qrCode" alt="QR Code" style="max-width: 100%; height: auto;" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onCloseDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    shift: Object,
    onCloseDialog: Function
  },
  data() {
    return {
      qrCode: "" // Holds the Base64 QR Code
    };
  },
  watch: {
    shift: {
      immediate: true,
      handler(newShift) {
        if (newShift && newShift.qr_code !== null || '') {
          // Ensure the base64 data has the correct prefix
          this.qrCode = newShift.qr_code.startsWith("data:image")
            ? newShift.qr_code
            : `data:image/png;base64,${newShift.qr_code}`;
        }
      }
    }
  }
};
</script>

<style scoped>
img {
  display: block;
  margin: 10px auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
  background-color: white;
}
</style>
